const skills = [
  {
    id: 1,
    title: "Back End",
    image: "backend_blue.png",
    languages: [
      {
        id: 1,
        title: "Java",
        css: "java",
      },
      {
        id: 2,
        title: "Go",
        css: "go",
      },
      {
        id: 3,
        title: "Mysql",
        css: "mysql",
      },
      {
        id: 4,
        title: "Middleware",
        css: "middleware",
      },
      {
        id: 5,
        title: "Spring Framework",
        css: "spring",
      },
    ],
    description:
      "I develop web apps using Java or Go and work closely with middleware to decouple the system and improve its performance, such as Redis, Elasticsearch, RabbitMQ.",
  },
  {
    id: 2,
    title: "Front End",
    image: "frontend_blue.png",
    languages: [
      {
        id: 1,
        title: "JavaScript",
        css: "js",
      },
      {
        id: 2,
        title: "React.js",
        css: "reactjs",
      },
      {
        id: 3,
        title: "Vue.js",
        css: "vuejs",
      },
      {
        id: 4,
        title: "Bootstrap",
        css: "bootstrap",
      },
    ],
    description:
      "Even though I am a backend developer, I have passion for designing web apps and websites and I am into such things as JavaScript, React.js and Vue.js",
  },
  {
    id: 3,
    title: "Devops",
    image: "devops.png",
    languages: [
      {
        id: 1,
        title: "Docker",
        css: "docker",
      },
      {
        id: 2,
        title: "AWS/GCP",
        css: "cloud",
      },
      {
        id: 3,
        title: "Terraform",
        css: "terraform",
      },
      {
        id: 4,
        title: "CI/CD",
        css: "cicd",
      },
    ],
    description:
      "In DevOps, I've optimized deployment processes using AWS, Docker, and Terraform, significantly reducing project deployment times. I've also implemented CI/CD pipelines with GitHub Actions and Docker containers, enhancing deployment workflows efficiently.",
  },
];

export default skills;
