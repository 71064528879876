const experience = [
  {
    id: 1,
    date: "Sept 2023 - Jan 2024",
    position: "Software Engineer Intern",
    company: "SigScalr",
    url: "https://www.sigscalr.io/",
    description: [
      {
        id: 1,
        description:
          "Collaborated as a part of an Agile team to create and test an index-free observability logging solution for storing compressed log events, resulting in significant space reduction and high-fidelity data queries under one second",
      },
      {
        id: 2,
        description:
          "Utilized Goroutines to asynchronously process query requests. Implemented a Go-based SPL parser to parse queries into an Abstract Syntax Tree, greatly improving the efficiency of expression result calculations",
      },
      {
        id: 3,
        description:
          "Developed backend Api for distributed tracing systems, providing insight into performance at each step of the request",
      },
      {
        id: 4,
        description:
          "Incorporated Docker image building into the deployment pipeline for seamless continuous delivery automation",
      },
    ],
  },
  {
    id: 2,
    date: "May 2023 - Aug 2023",
    position: "Software Engineer Intern",
    company: "1 Thing Org",
    url: "https://www.1thing.org/",
    description: [
      {
        id: 1,
        description:
          "Migrated existing applications from web-based platform to mobile app by adopting React Native and Typescript",
      },
      {
        id: 2,
        description:
          "Enhanced system performance by 55% by utilizing React Redux to manage multi-state across components",
      },
      {
        id: 1,
        description:
          "Developed user feedback page, incident chart by adopting D3.js, interacting with Google Cloud (Firebase Database)",
      },
    ],
  },
  {
    id: 3,
    date: "June 2021 - Aug 2021",
    position: "Software Engineer Intern",
    company: "USpeed Network Co., Ltd",
    url: "http://www.uspeed.com.cn",
    description: [
      {
        id: 1,
        description:
          "Constructed backend management systems by utilizing Spring Framework. Incorporating Test-Driven Development resulted in a 40% reduction in debugging time and led to an enhancement in code quality",
      },
      {
        id: 2,
        description:
          "Integrated RESTful APIs to handle HTTP requests and responses, and persisted data using the MySQL database",
      },
    ],
  },
  {
    id: 4,
    date: "July 2020 - Aug 2020",
    position: "Software Engineer Intern",
    company: "Shenzhen Das Intellitech Co., Ltd",
    url: "http://www.chn-das.com/en/index.aspx",
    description: [
      {
        id: 1,
        description:
          "Implemented multiple high visibility enhancements to company’s smart office system, such as meeting room planning, elevator scheduling and security tracking. Improved the utilization rate of office building resources by 45%",
      },
      {
        id: 2,
        description:
          "Conducted intensive stress testing of the back-end interface with JMeter and assisted group with establishing the functional testing framework of the project",
      },
    ],
  },
];

export default experience;
