const projects = [
  {
    id: 1,
    title: "Siglens - Open Source Observability",
    image: "siglens.jpg",
    url: "https://github.com/siglens/siglens",
    category: "Web Development",
    tags: ["Go", "Splunk", "Observability"],
    description:
      "I am the main contributer for an Open Source Observability Solution that is 100x more efficient than Splunk",
  },
  {
    id: 2,
    title: "Full-stack Interactive Blogging Platform",
    image: "blog.jpg",
    url: "https://github.com/Jiale-Fang/blog",
    category: "Web Development",
    tags: ["Java", "Vue.js", "Spring Framework", "CI/CD", "Middleware"],
    description:
      "Developed an open-source blogging web application with features like text/multimedia upload, content tagging and searching, real-time chatting, and social media integrations. Stored data in Cloud storage and Cloud SQL deployed the website on GCP Cloud (www.tcefrep.site) and accumulated over 3000 total users and 100,000 views",
  },
  {
    id: 3,
    title: "Distributed E - Commerce Platform",
    image: "guli.jpg",
    url: "https://github.com/Jiale-Fang/tcefrep-mall",
    category: "Web Development",
    tags: ["Java", "Docker", "Middleware", "Microservices"],
    description:
      "Designed and developed a highly scalable E-commerce platform leveraging Microservices architecture. Configured the microservices with Spring Cloud Gateway. Processed order service asynchronously, achieved peak load shifting for Prime Day Sale event using RabbitMQ, greatly reducing the systematic response time by 63%",
  },
];

export default projects;
